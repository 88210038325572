import { Injectable } from '@angular/core';
import {BaseService} from "./base-service";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {BackEndVersion} from "../models/backend-version.model";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class VersionService extends BaseService {
  private apiUrlBackEnd = this.baseApiUrl + 'BackEndVersion';
  private apiUrlFrontEnd = this.baseApiUrl + 'FrontEndVersion';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getBackEndVersion(): Observable<BackEndVersion> {
    console.log('Getting DataChecker Service and database schema version');

    return this.http.get<BackEndVersion>(this.apiUrlBackEnd)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // Gets the front-end version from the local variable
  getFrontEndVersion(): string {
    return environment.frontEndVersion;
  }

  // Sets the front-end version in the database through the back-end
  putFrontEndVersion(frontEndVersion: string): Observable<any> {
    console.log('Setting front-end version:', frontEndVersion);

    return this.http.put<string>(this.apiUrlFrontEnd + '?frontEndVersion=' + frontEndVersion, null)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
}
