<div class="container">
  <div>
    <form (ngSubmit)="onSubmit(jobForm)" #jobForm="ngForm">
      <div class="job-setup-container">
        <div class="single-column">
          <h3>Dagligt Job:</h3>
        </div>
        <div class="single-column">
          <label for="dailyTime">Tid:</label>
          <input type="time" id="dailyTime" name="dailyTime" [(ngModel)]="dailyJobTime" (ngModelChange)="onFormChange()" required>
        </div>
        <div class="single-column">
          <button type="button" (click)="startDailyJob()">Kør job</button>
        </div>
        <div class="single-column">
          <h3>Ugentligt Job:</h3>
        </div>
        <div class="single-column">
          <label for="weeklyDay">Dag:</label>
          <select id="weeklyDay" name="weeklyDay" [(ngModel)]="weeklyJobDay" (ngModelChange)="onFormChange()" required>
            <option *ngFor="let day of daysOfWeek" [value]="day">{{ day }}</option>
          </select>
        </div>
        <div class="single-column">
          <label for="weeklyTime">Tid:</label>
          <input type="time" id="weeklyTime" name="weeklyTime" [(ngModel)]="weeklyJobTime" (ngModelChange)="onFormChange()" required>
        </div>
        <div class="single-column">
          <button type="button" (click)="startWeeklyJob()">Kør job</button>
        </div>
        <div class="single-column">
          <button type="submit" [disabled]="!jobForm.form.valid || !formChanged">Gem</button>
        </div>
        <div class="single-column">
          <button type="button" (click)="onReset()">Nulstil</button>
        </div>
      </div>
    </form>
  </div>

  <div class="horizontal-container">
    <div class="table-container" [ngClass]="{'full-width': !selectedRow}">
      <mat-paginator #paginatorJobLog [pageSizeOptions]="[50, 100, 200, 500]" showFirstLastButtons></mat-paginator>
      <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="desc" class="mat-elevation-z8" #sortJobLog="matSort">
        <!-- Jobtype Column -->
        <ng-container matColumnDef="jobtype">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            <img *ngIf="row.jobtype === 1" src="assets/job-search-svgrepo-com.svg" alt="Manual Icon" class="small-icon">
            <img *ngIf="row.jobtype === 2" src="assets/process-gear-business-svgrepo-com.svg" alt="Recurring Icon" class="small-icon">
          </td>
        </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.id }}</td>
        </ng-container>

        <!-- Job Name Column -->
        <ng-container matColumnDef="jobnavn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Jobnavn</th>
          <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.jobnavn }}</td>
        </ng-container>

        <!-- Start Time Column -->
        <ng-container matColumnDef="starttid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Starttid</th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{ row.starttid | date: 'dd-MM-yyyy HH:mm:ss':'Europe/Copenhagen' }}
          </td>
        </ng-container>

        <!-- End Time Column -->
        <ng-container matColumnDef="sluttid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sluttid</th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{ row.sluttid | date: 'dd-MM-yyyy HH:mm:ss':'Europe/Copenhagen' }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            <img *ngIf="row.status === StatusIcons.Neutral" src="assets/night-moon-svgrepo-com.svg" alt="Neutral Icon" class="small-icon">
            <img *ngIf="row.status === StatusIcons.Running" src="assets/running-svgrepo-com.svg" alt="Neutral Icon" class="small-icon">
            <img *ngIf="row.status === StatusIcons.Error" src="assets/alarm-bell-alert-svgrepo-com.svg" alt="Neutral Icon" class="small-icon">
            <img *ngIf="row.status === StatusIcons.Completed" src="assets/sun-with-face-svgrepo-com.svg" alt="Neutral Icon" class="small-icon">
          </td>
        </ng-container>

        <!-- Use Timestamp Column -->
        <ng-container matColumnDef="useTimestampStr">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Brug tidsstempel</th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{ row.useTimestampStr }}
          </td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
            (click)="onRowClicked(row)"
            (mouseenter)="onRowHover(row)"
            (mouseleave)="onRowLeave()"
            [ngClass]="{
              'selected-row': selectedRow === row,
              'uneven-row': selectedRow !== row && !isEven(i),
              'even-row': selectedRow !== row && isEven(i)
            }">
        </tr>
      </table>
    </div>

    <div class="table-container" *ngIf="selectedRow">
      <mat-paginator #paginatorLogMessages [pageSizeOptions]="[50, 100, 200, 500]" showFirstLastButtons></mat-paginator>
      <div class="log-container">
        <table mat-table [dataSource]="dataSourceLog" #sortLogMessages="matSort" matSort matSortActive="timestamp" matSortDirection="desc" class="mat-elevation-z8">
          <!-- Beskedtype Column -->
          <ng-container matColumnDef="beskedtype">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let row" class="table-cell">
              <img *ngIf="row.beskedtype === 1" src="assets/information-svgrepo-com.svg" alt="Information Icon" class="small-icon">
              <img *ngIf="row.beskedtype === 2" src="assets/alarm-bell-alert-svgrepo-com.svg" alt="Alarm Icon" class="small-icon">
            </td>
          </ng-container>

          <!-- Timestamp Column -->
          <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tidsstempel</th>
            <td mat-cell *matCellDef="let row" class="table-cell">
              {{ row.timestamp | date: 'dd-MM-yyyy HH:mm:ss':'Europe/Copenhagen' }}
            </td>
          </ng-container>

          <!-- Objekttype Column -->
          <ng-container matColumnDef="objekttype">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Objekttype</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.objekttype }}</td>
          </ng-container>

          <!-- Datacheck Kilde Column -->
          <ng-container matColumnDef="datacheckKilde">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Datacheck kilde</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.datacheckKilde }}</td>
          </ng-container>

          <!-- Logbesked Column -->
          <ng-container matColumnDef="msg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Logbesked</th>
            <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.msg }}</td>
          </ng-container>

          <!-- Header and Row Declarations -->
          <tr mat-header-row *matHeaderRowDef="displayedColumnsLog"></tr>
          <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumnsLog;"
              [ngClass]="{
              'uneven-row': !isEvenLog(i),
              'even-row': isEvenLog(i)
            }">
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
