import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable} from "rxjs";
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class RunDataCheckService extends BaseService {
  private apiUrl = this.baseApiUrl + 'RunStoredProcedure';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  runDataCheck(storedProcedureNames: string[], useTimestamp: boolean): Observable<boolean> {
    console.log('Running dataCheck for stored procedures:', storedProcedureNames);

    const params = new HttpParams()
      .set('storedProcedureNames', storedProcedureNames.join(','))
      .set('useTimestamp', useTimestamp ? '1' : '0');

    return this.http.post(`${this.apiUrl}`, null, { params })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .pipe(
        map(() => true)
      );
  }
}
