import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  protected baseApiUrl = environment.apiUrl;

  constructor(protected snackBar: MatSnackBar) { }

  protected handleError(error: HttpErrorResponse) {
    let errorMessage = 'Something bad happened; please try again later.';
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
      errorMessage = 'A client-side or network error occurred; please try again later.';
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
      errorMessage = `Backend returned code ${error.status} message ${error.message}; please try again later.`;
    }
    this.snackBar.open(errorMessage, 'Close', {
      duration: 5000,
    });
    // Return an observable with a user-facing error message.
    return throwError(() => new Error(errorMessage));
  }
}
