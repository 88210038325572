import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AllResults} from '../models/all-results.model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AllResultsService extends BaseService {
  private apiUrl = this.baseApiUrl + 'Results';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getData(): Observable<AllResults[]> {
    return this.http.get<AllResults[]>(this.apiUrl)
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .pipe(
        map(results => this.modifyField(results))
    );
  }

  getFilteredData(onlyActive: string, objectType?: string, dataCheckSource?: string, dataCheck?: string, dataCheckRemark?: string): Observable<AllResults[]> {
    let params = new HttpParams();
    params = params.set('onlyActive', onlyActive);
    if (objectType) params = params.set('objectType', objectType);
    if (dataCheckSource) params = params.set('dataCheckSource', dataCheckSource);
    if (dataCheck) params = params.set('dataCheck', dataCheck);
    if (dataCheckRemark) params = params.set('dataCheckRemark', dataCheckRemark);

    return this.http.get<AllResults[]>(this.apiUrl, { params })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .pipe(
        map(results => this.modifyField(results))
      )
      .pipe(
        map(results => {
          if (results.length == 0) {
            this.snackBar.open("Der er ingen data i søgningen", 'Luk', {
              duration: 5000,
            });
          }
          return results;
        }
      ));
  }

  // Method to modify a field in AllResults
  private modifyField(results: AllResults[]): AllResults[] {
    return results.map(result => {
      // We change ',' to ' , ' in datacheckBem in order to make the table column widths work properly
      if (result.datacheckBem) {
        result.datacheckBem = result.datacheckBem.split(',').join(' , ');
      }
      return result;
    });
  }
}
