import {Component, OnInit, ViewChild} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {CommonModule, DatePipe, NgFor, NgIf} from "@angular/common";
import {JobSetupService} from "../../services/job-setup.service";
import {JobSetup} from "../../models/job-setup.model";
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, MatSortHeader, MatSortModule} from "@angular/material/sort";
import {MatIcon} from "@angular/material/icon";
import {StatusIcons} from "../../models/status-icons.enum";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {JobLogService} from "../../services/job-log.service";
import {JobLog} from "../../models/job-log.model";
import {LogJobService} from "../../services/log-job.service";
import {Log} from "../../models/log.model";
import {SignalRService} from "../../services/signalr.service";
import {MatDialog} from '@angular/material/dialog';
import {PasswordDialogComponent} from '../password-dialog/password-dialog.component';
import {MatSnackBar} from "@angular/material/snack-bar";
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-jobs',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    NgIf,
    NgFor,
    DatePipe,
    MatSort,
    MatSortHeader,
    MatIcon
  ],
  providers: [
    JobSetupService,
    JobLogService,
    LogJobService
  ],
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  daysOfWeek: string[] = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];
  dailyJobTime: string = '04:00';
  weeklyJobTime: string = '06:00';
  weeklyJobDay: string = 'Søndag';

  currentRow: any;
  selectedRow: any;
  selectedRowIndex: number = -1;
  formChanged: boolean = false;

  @ViewChild('sortJobLog', { static: true }) sortJobLog!: MatSort;
  @ViewChild('sortLogMessages', { static: false }) sortLogMessages!: MatSort;

  @ViewChild('paginatorJobLog') paginatorJobLog!: MatPaginator;
  @ViewChild('paginatorLogMessages') paginatorLogMessages!: MatPaginator;

  dataSource = new MatTableDataSource<JobLog>();
  dataSourceLog = new MatTableDataSource<Log>();

  displayedColumns: string[] = ['jobtype', 'id', 'jobnavn', 'starttid', 'sluttid', 'status', 'useTimestampStr'];
  displayedColumnsLog: string[] = ['beskedtype', 'timestamp', 'objekttype', 'datacheckKilde', 'msg'];

  jobSetup: JobSetup = {
    dailyJobTime: this.dailyJobTime,
    weeklyJobDay: this.daysOfWeek.indexOf(this.weeklyJobDay),
    weeklyJobTime: this.weeklyJobTime
  };

  constructor(
    private jobSetupService: JobSetupService,
    private jobLogService: JobLogService,
    private logJobService: LogJobService,
    private signalRService: SignalRService,
    private dialog: MatDialog,
    protected snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.jobSetupService.getData().subscribe(data => {
      this.jobSetup = data;
      this.dailyJobTime = this.jobSetup.dailyJobTime;
      this.weeklyJobTime = this.jobSetup.weeklyJobTime;
      this.weeklyJobDay = this.daysOfWeek[this.jobSetup.weeklyJobDay];
    });

    this.fetchData();

    this.signalRService.startConnection();
    this.signalRService.addJobLogListener(() => {
      console.log('NotifyJobLog');
      this.fetchData();
    });
  }

  ngAfterViewInit() {
    this.sortJobLog.disableClear = true;
    this.dataSource.sort = this.sortJobLog;
    this.dataSource.paginator = this.paginatorJobLog;
  }

 async onSubmit(form: any) {
    if (await this.passwordCorrect()) {
      const dailyJobTime = form.value.dailyTime;
      const weeklyJobDay = form.value.weeklyDay;
      const weeklyJobTime = form.value.weeklyTime;

      console.log('Daily Job Time:', dailyJobTime);
      console.log('Weekly Job Day:', weeklyJobDay);
      console.log('Weekly Job Time:', weeklyJobTime);

      this.jobSetup = {
        dailyJobTime: dailyJobTime,
        weeklyJobDay: this.daysOfWeek.indexOf(weeklyJobDay),
        weeklyJobTime: weeklyJobTime
      };

      this.jobSetupService.putData(this.jobSetup).subscribe(data => {
        console.log('Job settings saved:', data);
        this.formChanged = false;
      });
    }
  }

  async onReset() {
    if (await this.passwordCorrect()) {
      this.dailyJobTime = '04:00';
      this.weeklyJobTime = '06:00';
      this.weeklyJobDay = 'Søndag';
      this.formChanged = true;
    }
  }

  onFormChange(): void {
    this.formChanged = true;
  }

  protected readonly StatusIcons = StatusIcons;

  fetchData(): void {
    if (this.selectedRow) {
      this.selectedRowIndex = this.dataSource.data.findIndex(row => row.id === this.selectedRow.id);
    }
    this.jobLogService.getData().subscribe(fetchedData => {
      this.dataSource.data = fetchedData;
      if (this.selectedRowIndex !== -1) {
        this.selectedRow = this.dataSource.data[this.selectedRowIndex];
        this.logJobService.getData(this.selectedRow.id).subscribe(fetchedData => {
          this.dataSourceLog.data = fetchedData;

          this.initializeLogMessagesDataSource();
        });
      }
      else
      {
        this.selectedRow = null;
        this.dataSourceLog.data = [];
      }
    });
  }

  onRowClicked(row: any): void {
    if (this.selectedRow === row) {
      this.selectedRow = null;
      this.dataSourceLog.data = [];
    }
    else {
      this.selectedRow = row;

      this.logJobService.getData(this.selectedRow.id).subscribe(fetchedData => {
        this.dataSourceLog.data = fetchedData;

        this.initializeLogMessagesDataSource();
      });
    }
  }

  initializeLogMessagesDataSource(): void {
    this.sortLogMessages.disableClear = true;
    this.dataSourceLog.sort = this.sortLogMessages;
    this.dataSourceLog.paginator = this.paginatorLogMessages;
  }

  onRowHover(row: any): void {
    this.currentRow = row;
  }

  onRowLeave(): void {
    this.currentRow = null;
  }

  isEven(i: any): boolean {
    return i % 2 === 0;
  }

  isEvenLog(i: any): boolean {
    return i % 2 === 0;
  }

  startDailyJob() {
    this.asyncStartDailyJob().then();
  }

  async asyncStartDailyJob() {
    if (await this.passwordCorrect()) {
      this.jobSetupService.startDailyJob().subscribe(() => {
        console.log('Daily job started');
      });
    }
  }

  startWeeklyJob() {
    this.asyncStartWeeklyJob().then();
  }

  async asyncStartWeeklyJob() {
    if (await this.passwordCorrect()) {
      this.jobSetupService.startWeeklyJob().subscribe(() => {
        console.log('Weekly job started');
      });
    }
  }

  private async passwordCorrect(): Promise<boolean> {
    const dialogRef = this.dialog.open(PasswordDialogComponent);

    const result = await firstValueFrom(dialogRef.afterClosed());
    if (result === 'DTT72') {
      this.snackBar.open("Adgangskoden er korrekt", 'Luk', {
        duration: 5000,
      });
      return true;
    } else {
      console.log('Incorrect password');
      this.snackBar.open("Adgangskoden er forkert", 'Luk', {
        duration: 5000,
      });
      return false;
    }
  }
}

