import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StoredProcedure} from '../models/stored-procedure.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class StoredProceduresService extends BaseService {
  private apiUrl = this.baseApiUrl + 'StoredProcedures';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getData(): Observable<StoredProcedure[]> {
    return this.http.get<StoredProcedure[]>(this.apiUrl)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getObjectTypes(): Observable<string[]> {
    return this.getData().pipe(
      map(fetchedData => Array.from(new Set(fetchedData.map(data => data.objekttype).filter(kilde => kilde !== 'DIVERSE')))),
      catchError(this.handleError.bind(this))
    );
  }

  getDataCheckSources(): Observable<string[]> {
    return this.getData().pipe(
      map(fetchedData => Array.from(new Set(fetchedData.map(data => data.datacheckKilde).filter(kilde => kilde !== 'SP_KOPIER_RESULTATER')))),
      catchError(this.handleError.bind(this))
    );
  }

}
