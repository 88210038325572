import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Log} from "../models/log.model";
import {catchError} from "rxjs/operators";
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class LogDataCheckSourceService extends BaseService {
  private apiUrl = this.baseApiUrl + 'LogDataCheckSource';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getDataCheckSourceData(dataCheckSource: string): Observable<Log[]> {
    console.log('Getting log data for dataCheck source:', dataCheckSource);

    const params = new HttpParams()
      .set('dataCheckSource', dataCheckSource);

    return this.http.get<Log[]>(this.apiUrl, { params })
      .pipe(
          catchError(this.handleError.bind(this))
      );
  }
}
