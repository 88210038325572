<mat-toolbar color="primary">
  <span>{{ title }}</span>
  <span class="spacer"></span>
  <button mat-button routerLink="result-view" [ngClass]="{'active-route': isRouteActive('/result-view')}">
    <mat-icon>assessment</mat-icon>
    <span>Resultatoversigt</span>
  </button>
  <button mat-button routerLink="history-view" [ngClass]="{'active-route': isRouteActive('/history-view')}">
    <mat-icon>history</mat-icon>
    <span>Historik</span>
  </button>
  <button mat-button routerLink="processed-view" [ngClass]="{'active-route': isRouteActive('/processed-view')}">
    <mat-icon>published_with_changes</mat-icon>
    <span>Behandlede</span>
  </button>
  <button mat-button routerLink="all-results" [ngClass]="{'active-route': isRouteActive('/all-results')}">
    <mat-icon>view_headline</mat-icon>
    <span>Alle resultater</span>
  </button>
  <button mat-button routerLink="jobs" [ngClass]="{'active-route': isRouteActive('/jobs')}">
    <mat-icon>work</mat-icon>
    <span>Jobs</span>
  </button>
  <button mat-button routerLink="stored-procedures" [ngClass]="{'active-route': isRouteActive('/stored-procedures')}">
    <mat-icon>publish</mat-icon>
    <span>Procedurer</span>
  </button>
  <button mat-button routerLink="logs" [ngClass]="{'active-route': isRouteActive('/logs')}">
    <mat-icon>report</mat-icon>
    <span>Logs</span>
  </button>
  <button mat-button routerLink="settings" [ngClass]="{'active-route': isRouteActive('/settings')}">
    <mat-icon>settings</mat-icon>
    <span>Indstillinger</span>
  </button>
  <span class="spacer"></span>
  <button mat-button [matMenuTriggerFor]="menu">Menu</button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="result-overview">Resultatoversigt</button>
    <button mat-menu-item routerLink="history-view">Historik</button>
    <button mat-menu-item routerLink="processed-view">Behandlede</button>
    <button mat-menu-item routerLink="all-results">Alle resultater</button>
    <button mat-menu-item routerLink="jobs">Jobs</button>
    <button mat-menu-item routerLink="stored-procedures">Procedurer</button>
    <button mat-menu-item routerLink="logs">Logs</button>
    <button mat-menu-item routerLink="settings">Indstillinger</button>
  </mat-menu>
</mat-toolbar>

<router-outlet></router-outlet>

