import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Log} from "../models/log.model";
import {catchError} from "rxjs/operators";
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class LogJobService extends BaseService {
  private apiUrl = this.baseApiUrl + 'LogJob';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getData(jobLogId: number): Observable<Log[]> {
    console.log('Getting log data for job log:', jobLogId);

    const params = new HttpParams()
      .set('jobLogId', jobLogId);

    return this.http.get<Log[]>(this.apiUrl, { params })
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
}
