import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from "rxjs";
import {JobLog} from "../models/job-log.model";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class JobLogService extends BaseService {
  private apiUrl = this.baseApiUrl + 'JobLog';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getData(): Observable<JobLog[]> {
    console.log('Getting job log data');

    return this.http.get<JobLog[]>(this.apiUrl)
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .pipe(
        map(results => this.modifyField(results))
      );
  }

  private modifyField(results: JobLog[]): JobLog[] {
    return results.map(result => {
      if (result.brugTidsstempel) {
        result.useTimestampStr = "Ja";
      }
      else {
        result.useTimestampStr = "Nej";
      }
      return result;
    });
  }
}
