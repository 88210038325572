import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {CommonModule} from '@angular/common';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {HttpClientModule} from '@angular/common/http';
import {AllResultsService} from '../../services/all-results.service';
import {AllResults} from '../../models/all-results.model';
import {MatIcon} from "@angular/material/icon";
import {MatMenuModule, MatMenuTrigger} from '@angular/material/menu';
import {MatFormField, MatInput, MatLabel} from "@angular/material/input";
import {MatIconButton} from "@angular/material/button";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {StoredProceduresService} from "../../services/stored-procedures.service";
import {MatOption, MatSelect} from "@angular/material/select";
import {ResultUniqueDataCheckService} from "../../services/result-unique-data-check.service";
import {ResultUniqueDataCheckRemarkService} from "../../services/result-unique-data-check-remark.service";
import {MatCheckbox} from "@angular/material/checkbox";

@Component({
  selector: 'app-all-results',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    HttpClientModule,
    MatIcon,
    MatMenuModule,
    MatInput,
    MatIconButton,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatLabel,
    MatCheckbox
  ],
  providers: [
    AllResultsService,
    StoredProceduresService,
    ResultUniqueDataCheckService,
    ResultUniqueDataCheckRemarkService
  ],
  templateUrl: './all-results.component.html',
  styleUrl: './all-results.component.css'
})
export class AllResultsComponent implements OnInit {
  currentRow: any;
  selectedRow: any;
  currentContextMenuRow: any;
  isActive: boolean = true;
  selectedObjectType: string | undefined;
  selectedDataCheckSource: string | undefined;
  selectedDataCheck: string | undefined;
  selectedDataCheckRemark: string | undefined;
  objectTypes: string[] = [];
  dataCheckSources: string[] = [];
  dataChecks: string[] = [];
  dataCheckRemarks: string[] = [];
  formChanged: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger | undefined;

  dataSource = new MatTableDataSource<AllResults>();
  displayedColumns: string[] = ['id', 'objekttype', 'mmId', 'gdbId', 'idLokalid', 'dcStatus', 'datacheckKilde', 'datacheck', 'datacheckBem'];

  contextMenuPosition = { x: '0px', y: '0px' };

  constructor(
    private allResultsService: AllResultsService,
    private storedProceduresService: StoredProceduresService,
    private resultUniqueDataCheckService: ResultUniqueDataCheckService,
    private resultUniqueDataCheckRemarkService: ResultUniqueDataCheckRemarkService
  ) {
  }

  ngOnInit(): void {
    this.fetchStaticData();
    this.dataSource.filterPredicate = (data: AllResults, filter: string) => {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        return currentTerm + (data as { [key: string]: any })[key] + '◬';
      }, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.sort.sortChange.subscribe(() => {
      this.selectedRow = null;
    });
  }

  async onSubmit(form: any) {
    const selectedObjectType = form.value.objectType;
    const selectedDataCheckSource = form.value.dataCheckSource;
    const selectedDataCheck = form.value.dataCheck;
    const selectedDataCheckRemark = form.value.dataCheckRemark;

    console.log('Onlyactive: ', this.isActive);
    this.fetchData(this.isActive, selectedObjectType, selectedDataCheckSource, selectedDataCheck, selectedDataCheckRemark);

    this.formChanged = false;
  }

  onReset() {
      this.isActive = true;
      this.selectedObjectType = "";
      this.selectedDataCheckSource = "";
      this.selectedDataCheck = "";
      this.selectedDataCheckRemark = "";
      this.formChanged = false;
      this.dataSource.data = [];
  }

  onFormChange(): void {
    if (this.selectedObjectType || this.selectedDataCheckSource || this.selectedDataCheck || this.selectedDataCheckRemark) {
      this.formChanged = true;
    }
    else
    {
      this.formChanged = false;
    }
  }

  fetchStaticData(): void {
    this.storedProceduresService.getObjectTypes().subscribe(data => {
      this.objectTypes = data;
    });
    this.storedProceduresService.getDataCheckSources().subscribe(data => {
      this.dataCheckSources = data;
    });
    this.resultUniqueDataCheckService.getData().subscribe(data => {
      this.dataChecks = data;
    });
    this.resultUniqueDataCheckRemarkService.getData().subscribe(data => {
      this.dataCheckRemarks = data;
    });
  }

  fetchData(onlyActive: boolean, objectType: string  , dataCheckSource: string, dataCheck: string, dataCheckRemark: string): void {
    this.allResultsService.getFilteredData(onlyActive ? 'true' : 'false', objectType, dataCheckSource, dataCheck, dataCheckRemark)
      .subscribe(fetchedData => {
        this.dataSource.data = fetchedData;
        this.selectedRow = null;
    });
  }

  applyFilter(filterValue: any): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter(filterInput: HTMLInputElement): void {
    filterInput.value = '';
    this.dataSource.filter = '';
  }

  onRowClicked(row: any): void {
    if (this.selectedRow === row) {
      this.selectedRow = null;
    }
    else {
      this.selectedRow = row;
    }
  }

  onRowHover(row: any): void {
    this.currentRow = row;
    if (this.currentContextMenuRow != this.currentRow) {
      this.closeContextMenu();
    }
  }

  onRowLeave(): void {
    this.currentRow = null;
  }

  isEven(i: any): boolean {
    return i % 2 === 0;
  }

  openContextMenu(event: MouseEvent, row: any, displayValue: any): void {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    if (this.contextMenu && this.contextMenu.menu) {
      this.currentContextMenuRow = this.currentRow;
      this.contextMenu.menuData = {'item': displayValue, 'row': row};
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  closeContextMenu(): void {
    if (this.contextMenu) {
      this.contextMenu.closeMenu();
    }
  }

  onFilter(filterInput: HTMLInputElement, displayValue: any): void {
    filterInput.value = displayValue;
    this.dataSource.filter = displayValue;
  }
}
