import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Observable, from } from 'rxjs';
import { ResultView } from '../models/result-view.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CosmosClient } from '@azure/cosmos';
import {IResultViewService} from "./iresult-view-service";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResultViewCosmosService extends BaseService implements IResultViewService {
  private client: CosmosClient;
  private databaseId = 'datacheck';
  private containerId = 'result_view';

  constructor(snackBar: MatSnackBar) {
    super(snackBar);
    this.client = new CosmosClient({ endpoint: environment.cosmosEndpoint, key: environment.cosmosKey });
  }

  getData(): Observable<ResultView[]> {
    const container = this.client.database(this.databaseId).container(this.containerId);
    const querySpec = {
      query: `
      SELECT
        c.OBJEKTTYPE AS objekttype,
        c.STATUS AS status,
        c.OBJEKT_AKTUALITET AS objektAktualitet,
        c.DATACHECK AS datacheck,
        c.DATACHECK_BEM AS datacheckBem,
        c.KORR_STATUS AS korrStatus,
        c["I alt"] AS iAlt
      FROM c
    `
    };

    console.log('Executing query:', querySpec.query);

    return from(container.items.query<ResultView>(querySpec).fetchAll().then(response => {
      console.log('Query response:', response.resources);
      return response.resources.sort((a, b) => {
        const objekttypeComparison = a.objekttype.localeCompare(b.objekttype);
        if (objekttypeComparison !== 0) {
          return objekttypeComparison;
        }
        const datacheckComparison = a.datacheck.localeCompare(b.datacheck);
        if (datacheckComparison !== 0) {
          return datacheckComparison;
        }
        return (a.datacheckBem || '').localeCompare((b.datacheckBem || ''));
      })
    }).catch(error => {
      console.error('Error during query execution:', error);
      this.snackBar.open('An error occurred while fetching data. Please try again later.', 'Close', {
        duration: 5000,
      });
      throw error;
    }));
  }
}
