<div class="vertical-container">
  <div class="filter-container">
    <input #filterInput matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
    <button mat-icon-button (click)="clearFilter(filterInput)">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-paginator [pageSizeOptions]="[50, 100, 200, 500]" showFirstLastButtons></mat-paginator>
  <div class="container">
    <div class="details-container" *ngIf="selectedRow">
      <h3 class="details-header">Detaljer for {{ selectedRow.objekttype }}</h3>
      <table class="details-table">
        <tr>
          <td class="details-td-title">Objekt type:</td>
          <td class="details-td-prop">{{ selectedRow.objekttype }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Datacheck kilde:</td>
          <td class="details-td-prop">{{ selectedRow.datacheckKilde }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Status:</td>
          <td class="details-td-prop">{{ selectedRow.status }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Objektaktualitet:</td>
          <td class="details-td-prop">{{ selectedRow.objektAktualitet }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Datacheck:</td>
          <td class="details-td-prop">{{ selectedRow.datacheck }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Denne uge:</td>
          <td class="details-td-prop">{{ selectedRow.denneUge }}</td>
        </tr>
        <tr>
          <td class="details-td-title">1 uge siden:</td>
          <td class="details-td-prop">{{ selectedRow._1UgeSiden }}</td>
        </tr>
        <tr>
          <td class="details-td-title">2 uger siden:</td>
          <td class="details-td-prop">{{ selectedRow._2UgerSiden }}</td>
        </tr>
        <tr>
          <td class="details-td-title">3 uger siden:</td>
          <td class="details-td-prop">{{ selectedRow._3UgerSiden }}</td>
        </tr>
        <tr>
          <td class="details-td-title">4 uger siden:</td>
          <td class="details-td-prop">{{ selectedRow._4UgerSiden }}</td>
        </tr>
        <tr>
          <td class="details-td-title">5 uger siden:</td>
          <td class="details-td-prop">{{ selectedRow._5UgerSiden }}</td>
        </tr>
        <tr>
          <td class="details-td-title">6 uger siden:</td>
          <td class="details-td-prop">{{ selectedRow._6UgerSiden }}</td>
        </tr>
        <tr>
          <td class="details-td-title">7 uger siden:</td>
          <td class="details-td-prop">{{ selectedRow._7UgerSiden }}</td>
        </tr>
        <tr>
          <td class="details-td-title">8 uger siden:</td>
          <td class="details-td-prop">{{ selectedRow._8UgerSiden }}</td>
        </tr>
      </table>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <!-- Objekttype Column -->
        <ng-container matColumnDef="objekttype">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Objekttype</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.objekttype)"> {{ row.objekttype }}</td>
        </ng-container>

        <!-- Datacheck Kilde Column -->
        <ng-container matColumnDef="datacheckKilde">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datacheck kilde</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.datacheckKilde)"> {{ row.datacheckKilde }}</td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.status)"> {{ row.status }}</td>
        </ng-container>

        <!-- Objekt Aktualitet Column -->
        <ng-container matColumnDef="objektAktualitet">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Objektaktualitet</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.objektAktualitet)"> {{ row.objektAktualitet }}</td>
        </ng-container>

        <!-- Datacheck Column -->
        <ng-container matColumnDef="datacheck">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datacheck</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.datacheck)"> {{ row.datacheck }}</td>
        </ng-container>

        <!-- Denne Uge Column -->
        <ng-container matColumnDef="denneUge">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Denne uge</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.denneUge)"> {{ row.denneUge }}</td>
        </ng-container>

        <!-- 1 uge siden Column -->
        <ng-container matColumnDef="enUgeSiden">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>1 uge siden</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row._1UgeSiden)"> {{ row._1UgeSiden }}</td>
        </ng-container>

        <!-- 2 uger siden Column -->
        <ng-container matColumnDef="toUgerSiden">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>2 uger siden</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row._2UgerSiden)"> {{ row._2UgerSiden }}</td>
        </ng-container>

        <!-- 3 uger siden Column -->
        <ng-container matColumnDef="treUgerSiden">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>3 uger siden</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row._3UgerSiden)"> {{ row._3UgerSiden }}</td>
        </ng-container>

        <!-- 4 uger siden Column -->
        <ng-container matColumnDef="fireUgerSiden">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>4 uger siden</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row._4UgerSiden)"> {{ row._4UgerSiden }}</td>
        </ng-container>

        <!-- 5 uger siden Column -->
        <ng-container matColumnDef="femUgerSiden">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>5 uger siden</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row._5UgerSiden)"> {{ row._5UgerSiden }}</td>
        </ng-container>

        <!-- 6 uger siden Column -->
        <ng-container matColumnDef="seksUgerSiden">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>6 uger siden</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row._6UgerSiden)"> {{ row._6UgerSiden }}</td>
        </ng-container>

        <!-- 7 uger siden Column -->
        <ng-container matColumnDef="syvUgerSiden">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>7 uger siden</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row._7UgerSiden)"> {{ row._7UgerSiden }}</td>
        </ng-container>

        <!-- 8 uger siden Column -->
        <ng-container matColumnDef="otteUgerSiden">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>8 uger siden</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row._8UgerSiden)"> {{ row._8UgerSiden }}</td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
            (click)="onRowClicked(row)"
            (mouseenter)="onRowHover(row)"
            (mouseleave)="onRowLeave()"
            [ngClass]="{
              'selected-row': selectedRow === row,
              'uneven-row': selectedRow !== row && !isEven(i),
              'even-row': selectedRow !== row && isEven(i)
            }">
        </tr>
      </table>
    </div>
  </div>
</div>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" [hasBackdrop]="false" (mouseleave)="closeContextMenu()">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="onFilter(filterInput, item)">Brug som filter</button>
  </ng-template>
</mat-menu>
