import {Component, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {StoredProceduresService} from '../../services/stored-procedures.service';
import {StoredProcedure} from '../../models/stored-procedure.model';
import {MatIcon} from "@angular/material/icon";
import {MatButtonModule} from '@angular/material/button';
import {RunDataCheckService} from '../../services/run-data-check.service';
import {SignalRService} from '../../services/signalr.service';
import {SelectionModel} from '@angular/cdk/collections';
import {MatCheckbox} from "@angular/material/checkbox";
import {UpdateStoredProcedureService} from "../../services/update-stored-procedure.service";
import {StatusIcons} from "../../models/status-icons.enum";
import {Log} from "../../models/log.model";
import {LogDataCheckSourceService} from "../../services/log-datachecksource.service";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {FormsModule} from '@angular/forms';
import {PasswordDialogComponent} from "../password-dialog/password-dialog.component";
import {firstValueFrom} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'stored-procedures',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIcon,
    MatButtonModule,
    MatCheckbox
  ],
  providers: [
    StoredProceduresService,
    RunDataCheckService,
    SignalRService,
    UpdateStoredProcedureService,
    LogDataCheckSourceService
  ],
  templateUrl: './stored-procedures.component.html',
  styleUrl: './stored-procedures.component.css'
})
export class StoredProceduresComponent implements OnInit {
  currentRow: any;
  currentRowLog: any;
  selectedRow: any;
  selectedRowIndex: number = -1;
  selectedRowLog: any;
  selection = new SelectionModel<StoredProcedure>(true, []);

  @ViewChild(MatPaginator) paginatorLogMessages!: MatPaginator;
  @ViewChild('sortLogMessages', { static: false }) sortLogMessages!: MatSort;

  dataSource = new MatTableDataSource<StoredProcedure>();
  dataSourceLog = new MatTableDataSource<Log>();

  displayedColumns: string[] = ['select', 'status', 'navn'];
  displayedColumnsLog: string[] = ['id', 'msg', 'timestamp'];

  useTimestamp: boolean = true;

  constructor(
    private storedProceduresService: StoredProceduresService,
    private runDataCheckService: RunDataCheckService,
    private signalRService: SignalRService,
    private updateStoredProcedureService: UpdateStoredProcedureService,
    private logDataCheckSourceService: LogDataCheckSourceService,
    private dialog: MatDialog,
    protected snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.fetchData();

    this.signalRService.startConnection();
    this.signalRService.addProcedureStatusListener((message: string) => {
      console.log('Procedure status update: ' + message);
      this.fetchData();
    });
  }

  fetchData(): void {
    if (this.selectedRow) {
      this.selectedRowIndex = this.dataSource.data.findIndex(row => row.navn === this.selectedRow.navn);
    }
    this.storedProceduresService.getData().subscribe(fetchedData => {
      this.dataSource.data = fetchedData;
      this.selection.clear();
      this.currentRowLog = null;
      this.selectedRowLog = null;
      if (this.selectedRowIndex !== -1) {
        this.selectedRow = this.dataSource.data[this.selectedRowIndex];
        this.fetchDataLog(this.selectedRow.datacheckKilde);
      }
      else
      {
        this.dataSourceLog.data = [];
        this.selectedRow = null;
      }
    });
  }

  fetchDataLog(datacheckSource: string): void {
    this.logDataCheckSourceService.getDataCheckSourceData(datacheckSource).subscribe(fetchedData => {
      this.dataSourceLog.data = fetchedData;

      this.initializeLogMessagesDataSource();
    });
  }

  onRowClicked(row: any): void {
    if (this.selectedRow === row) {
      this.selectedRow = null;
      this.currentRowLog = null;
      this.selectedRowLog = null;
      this.dataSourceLog.data = [];
    }
    else {
      this.selectedRow = row;
      this.fetchDataLog(row.datacheckKilde);
    }
  }

  initializeLogMessagesDataSource(): void {
    this.sortLogMessages.disableClear = true;
    this.dataSourceLog.sort = this.sortLogMessages;
    this.dataSourceLog.paginator = this.paginatorLogMessages;
  }

  onRowHover(row: any): void {
    this.currentRow = row;
  }

  onRowLeave(): void {
    this.currentRow = null;
  }

  isEven(i: any): boolean {
    return i % 2 === 0;
  }

  onRowClickedLog(row: any): void {
    if (this.selectedRowLog === row) {
      this.selectedRowLog = null;
    }
    else {
      this.selectedRowLog = row;
    }
  }

  onRowHoverLog(row: any): void {
    this.currentRowLog = row;
  }

  onRowLeaveLog(): void {
    this.currentRowLog = null;
  }

  isEvenLog(i: any): boolean {
    return i % 2 === 0;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: StoredProcedure): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.navn}`;
  }

 async startProcedures(useTimestampChk: MatCheckbox) {
    if (await this.passwordCorrect()) {
      if (this.selection.selected.length > 0) {
        const storedProcedureNames: string[] = [];
        this.selection.selected.forEach(selectedRow => {
          console.log('Starting procedure: ' + selectedRow.navn);
          storedProcedureNames.push(selectedRow.navn);
        });
        let useTimestamp: boolean = useTimestampChk.checked;
        this.runDataCheckService.runDataCheck(storedProcedureNames, useTimestamp).subscribe(() => {
          this.fetchData();
        });
      }
    }
  }

  resetProcedures() {
    if (this.selection.selected.length > 0) {
      const storedProcedureIds: string[] = [];
      this.selection.selected.forEach(selectedRow => {
        storedProcedureIds.push(selectedRow.id.toString());
      });
      this.updateStoredProcedureService.updateStoredProcedures(storedProcedureIds, StatusIcons.Neutral).subscribe(() => {
        this.fetchData();
      });
    }
  }

  private async passwordCorrect(): Promise<boolean> {
    const dialogRef = this.dialog.open(PasswordDialogComponent);

    const result = await firstValueFrom(dialogRef.afterClosed());
    if (result === 'DTT72') {
      this.snackBar.open("Adgangskoden er korrekt. Jobbet starter snart", 'Luk', {
        duration: 5000,
      });
      return true;
    } else {
      console.log('Incorrect password');
      this.snackBar.open("Adgangskoden er forkert", 'Luk', {
        duration: 5000,
      });
      return false;
    }
  }
  protected readonly StatusIcons = StatusIcons;
}
