import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ResultView} from '../models/result-view.model';
import {catchError, map} from "rxjs/operators";
import {MatSnackBar} from '@angular/material/snack-bar';
import {IResultViewService} from "./iresult-view-service";

@Injectable({
  providedIn: 'root'
})
export class ResultViewService extends BaseService implements IResultViewService {
  private apiUrl = this.baseApiUrl + 'ResultView';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getData(): Observable<ResultView[]> {
    return this.http.get<ResultView[]>(this.apiUrl)
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .pipe(
        map(results => this.modifyField(results))
    );
  }

  // Method to modify a field in ResultView
  private modifyField(results: ResultView[]): ResultView[] {
    return results.map(result => {
      // We change ',' to ' , ' in dataCheckBem in order to make the table column widths work properly
      if (result.datacheckBem) {
        result.datacheckBem = result.datacheckBem.split(',').join(' , ');
      }
      return result;
    });
  }
}
