import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from "rxjs";
import {JobSetup} from "../models/job-setup.model";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class JobSetupService extends BaseService {
  private apiUrl = this.baseApiUrl + 'JobSetup';
  private runDailyJobApiUrl = this.baseApiUrl + 'RunDailyJob';
  private runWeeklyJobApiUrl = this.baseApiUrl + 'RunWeeklyJob';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getData(): Observable<JobSetup> {
    console.log('Getting job setup');

    return this.http.get<JobSetup>(this.apiUrl)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  putData(jobSetup: JobSetup): Observable<any> {
    console.log('Updating job setup:', jobSetup);

    return this.http.put<JobSetup>(this.apiUrl, jobSetup)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  startDailyJob(): Observable<any> {
    console.log('Running daily job');

    return this.http.post(this.runDailyJobApiUrl, null)
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .pipe(
        map(() => true)
      );
  }

  startWeeklyJob(): Observable<any> {
    console.log('Running weekly job');

    return this.http.post(`${this.runWeeklyJobApiUrl}`, null, {})
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .pipe(
        map(() => true)
      );
  }
}
