import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {BaseService} from "./base-service";
import {EmailNotification} from "../models/email-notification.model";

@Injectable({
  providedIn: 'root'
})
export class EmailNotificationService extends BaseService {
  private apiUrl = this.baseApiUrl + 'EmailNotification';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getData(): Observable<EmailNotification[]> {
    return this.http.get<EmailNotification[]>(this.apiUrl)
      .pipe(
        catchError(this.handleError.bind(this))
      )
  }

  putData(emailNotification: EmailNotification): Observable<any> {
    console.log('Updating email notification:', emailNotification);

    return this.http.put<EmailNotification>(this.apiUrl, emailNotification)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  postData(emailNotification: EmailNotification): Observable<any> {
    console.log('Adding email notification:', emailNotification);

    return this.http.post<EmailNotification>(this.apiUrl, emailNotification)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  deleteData(emailNotification: EmailNotification): Observable<any> {
    console.log('Deleting email notification:', emailNotification);

    return this.http.delete<EmailNotification>(this.apiUrl + '/' + emailNotification.id)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
}
