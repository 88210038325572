<div class="vertical-container">
  <div class="filter-container">
    <input #filterInput matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
    <button mat-icon-button (click)="clearFilter(filterInput)">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-paginator [pageSizeOptions]="[50, 100, 200, 500]" showFirstLastButtons></mat-paginator>
  <div class="container">
    <div class="details-container" *ngIf="selectedRow">
      <h3 class="details-header">Detaljer for {{ selectedRow.objekttype }}</h3>
      <table class="details-table">
        <tr>
          <td class="details-td-title">Objekt type:</td>
          <td class="details-td-prop">{{ selectedRow.objekttype }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Status:</td>
          <td class="details-td-prop">{{ selectedRow.status }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Objektaktualitet:</td>
          <td class="details-td-prop">{{ selectedRow.objektAktualitet }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Datacheck:</td>
          <td class="details-td-prop">{{ selectedRow.datacheck }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Datacheck bemærkning:</td>
          <td class="details-td-prop">{{ selectedRow.datacheckBem }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Korrektionsstatus:</td>
          <td class="details-td-prop">{{ selectedRow.korrektionsStatus }}</td>
        </tr>
        <tr>
          <td class="details-td-title">I alt:</td>
          <td class="details-td-prop">{{ selectedRow.iAlt }}</td>
        </tr>
      </table>
    </div>
  <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <!-- Objekttype Column -->
        <ng-container matColumnDef="objekttype">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Objekttype</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.objekttype)"> {{ row.objekttype }}</td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.status)"> {{ row.status }}</td>
        </ng-container>

        <!-- Objekt Aktualitet Column -->
        <ng-container matColumnDef="objektAktualitet">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Objektaktualitet</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.objektAktualitet)"> {{ row.objektAktualitet }}</td>
        </ng-container>

        <!-- Datacheck Column -->
        <ng-container matColumnDef="datacheck">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datacheck</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.datacheck)"> {{ row.datacheck }}</td>
        </ng-container>

        <!-- Datacheck Bem Column -->
        <ng-container matColumnDef="datacheckBem">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datacheck bemærkning</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.datacheckBem)"> {{ row.datacheckBem }}</td>
        </ng-container>

        <!-- Korrektionsstatus Column -->
        <ng-container matColumnDef="korrStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Korrektionsstatus</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.korrStatus)"> {{ row.korrStatus}}</td>
        </ng-container>

        <!-- I alt Column -->
        <ng-container matColumnDef="iAlt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>I alt</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.iAlt)"> {{ row.iAlt }}</td>
        </ng-container>

      <!-- Header and Row Declarations -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
            (click)="onRowClicked(row)"
            (mouseenter)="onRowHover(row)"
            (mouseleave)="onRowLeave()"
            [ngClass]="{
              'selected-row': selectedRow === row,
              'uneven-row': selectedRow !== row && !isEven(i),
              'even-row': selectedRow !== row && isEven(i)
            }">
        </tr>
      </table>
    </div>
  </div>
</div>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" [hasBackdrop]="false" (mouseleave)="closeContextMenu()">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="onFilter(filterInput, item)">Brug som filter</button>
  </ng-template>
</mat-menu>
