import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {CommonModule} from '@angular/common';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {HttpClientModule} from '@angular/common/http';
import {ResultView} from '../../models/result-view.model';
import {MatIcon} from "@angular/material/icon";
import {MatMenuModule, MatMenuTrigger} from '@angular/material/menu';
import {MatInput} from "@angular/material/input";
import {MatIconButton} from "@angular/material/button";
import {IResultViewService} from "../../services/iresult-view-service";
import {RESULT_VIEW_SERVICE} from "../../app.component";

@Component({
  selector: 'app-result-overview',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    HttpClientModule,
    MatIcon,
    MatMenuModule,
    MatInput,
    MatIconButton
  ],
  providers: [
  ],
  templateUrl: './result-view.component.html',
  styleUrl: './result-view.component.css'
})
export class ResultViewComponent implements OnInit {
  currentRow: any;
  selectedRow: any;
  currentContextMenuRow: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger | undefined;

  dataSource = new MatTableDataSource<ResultView>();

  displayedColumns: string[] = ['objekttype', 'status', 'objektAktualitet', 'datacheck', 'datacheckBem', 'korrStatus', 'iAlt'];

  contextMenuPosition = { x: '0px', y: '0px' };

  constructor(@Inject(RESULT_VIEW_SERVICE) private resultViewService: IResultViewService) {
  }

  ngOnInit(): void {
    this.fetchData();
    this.dataSource.filterPredicate = (data: ResultView, filter: string) => {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        return currentTerm + (data as { [key: string]: any })[key] + '?';
      }, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.sort.sortChange.subscribe(() => {
      this.selectedRow = null;
    });
  }

  fetchData(): void {
    this.resultViewService.getData().subscribe(fetchedData => {
      this.dataSource.data = fetchedData;
    });
  }

  applyFilter(filterValue: any): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter(filterInput: HTMLInputElement): void {
    filterInput.value = '';
    this.dataSource.filter = '';
  }

  onRowClicked(row: any): void {
    if (this.selectedRow === row) {
      this.selectedRow = null;
    }
    else {
      this.selectedRow = row;
    }
  }

  onRowHover(row: any): void {
    this.currentRow = row;
    if (this.currentContextMenuRow != this.currentRow) {
      this.closeContextMenu();
    }
  }

  onRowLeave(): void {
    this.currentRow = null;
  }

  isEven(i: any): boolean {
    return i % 2 === 0;
  }

  openContextMenu(event: MouseEvent, row: any, displayValue: any): void {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    if (this.contextMenu && this.contextMenu.menu) {
      this.currentContextMenuRow = this.currentRow;
      this.contextMenu.menuData = {'item': displayValue, 'row': row};
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  closeContextMenu(): void {
    if (this.contextMenu) {
      this.contextMenu.closeMenu();
    }
  }

  onFilter(filterInput: HTMLInputElement, displayValue: any): void {
    filterInput.value = displayValue;
    this.dataSource.filter = displayValue;
  }
}
