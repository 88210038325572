import {Injectable} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withStatefulReconnect()
      .withUrl(`${environment.apiUrl}notificationHub`, {
        withCredentials: false
      })
      .build();
  }

  startConnection(): void {
    if (this.hubConnection.state == signalR.HubConnectionState.Disconnected) {
      console.log(`Starting SignalR connection with ${environment.apiUrl}notificationHub`);
      this.hubConnection.start()
        .catch(err => console.error('Error while starting connection: ' + err));
    }
  }

  addProcedureStatusListener(callback: (message: string) => void): void {
    console.log('Adding listener for ProcedureStatus...');
    this.hubConnection.on('NotifyProcedureStatus', (message: string) => {
      console.log('Received notification:', message);
      callback(message);
    });
  }

  addJobLogListener(callback: () => void): void {
    console.log('Adding listener for JobLog...');
    this.hubConnection.on('NotifyJobLog', () => {
      console.log('Received notification');
      callback();
    });
  }
}
