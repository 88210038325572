<div class="vertical-container">
  <form (ngSubmit)="onSubmit(filterForm)" #filterForm="ngForm">
    <div class="search-filter-container">
        <mat-checkbox [(ngModel)]="isActive" name="active" (ngModelChange)="onFormChange()" style="margin-bottom: 18px;">Kun aktive</mat-checkbox>
        <mat-form-field>
          <mat-label>Objekttype</mat-label>
          <mat-select [(ngModel)]="selectedObjectType" name="objectType" (ngModelChange)="onFormChange()" >
            <mat-option style="font-size: 12px;" [value]="null">Ingen</mat-option> <!-- Option to deselect -->
            <mat-option style="font-size: 12px;" *ngFor="let type of objectTypes" [value]="type">{{ type }}</mat-option>
          </mat-select>
        </mat-form-field>
      <mat-form-field>
        <mat-label>Datacheck kilde</mat-label>
        <mat-select [(ngModel)]="selectedDataCheckSource" name="dataCheckSource" (ngModelChange)="onFormChange()" >
          <mat-option style="font-size: 12px;" [value]="null">Ingen</mat-option> <!-- Option to deselect -->
          <mat-option style="font-size: 12px;" *ngFor="let type of dataCheckSources" [value]="type">{{ type }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Datacheck</mat-label>
        <mat-select [(ngModel)]="selectedDataCheck" name="dataCheck" (ngModelChange)="onFormChange()" >
          <mat-option style="font-size: 12px;" [value]="null">Ingen</mat-option> <!-- Option to deselect -->
          <mat-option style="font-size: 12px;" *ngFor="let type of dataChecks" [value]="type" >{{ type }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Datacheck bemærkning</mat-label>
        <mat-select [(ngModel)]="selectedDataCheckRemark" name="dataCheckRemark" (ngModelChange)="onFormChange()" >
          <mat-option style="font-size: 12px;" [value]="null">Ingen</mat-option> <!-- Option to deselect -->
          <mat-option style="font-size: 12px;" *ngFor="let type of dataCheckRemarks" [value]="type">{{ type }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button type="submit" [disabled]="!filterForm.form.valid" style="width: 80px; height: 40px; margin-bottom: 20px;">Søg</button>
      <button type="button" (click)="onReset()" style="width: 80px; height: 40px; margin-bottom: 20px;">Nulstil</button>
    </div>
  </form>
  <div class="filter-container">
    <input #filterInput matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
    <button mat-icon-button (click)="clearFilter(filterInput)">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-paginator [pageSizeOptions]="[50, 100, 200, 500]" showFirstLastButtons></mat-paginator>
  <div class="container">
    <div class="details-container" *ngIf="selectedRow">
      <h3 class="details-header">Detaljer for {{ selectedRow.objekttype }}</h3>
      <table class="details-table">
        <tr>
          <td class="details-td-title">Id:</td>
          <td class="details-td-prop">{{ selectedRow.id }}</td>
        </tr>
        <tr>
          <td class="details-td-title">MM id:</td>
          <td class="details-td-prop">{{ selectedRow.mmId }}</td>
        </tr>
        <tr>
          <td class="details-td-title">MM virkningfra:</td>
          <td class="details-td-prop">{{ selectedRow.mmVirkningFra }}</td>
        </tr>
        <tr>
          <td class="details-td-title">MM virkningtil:</td>
          <td class="details-td-prop">{{ selectedRow.mmVirkningTil }}</td>
        </tr>
        <tr>
          <td class="details-td-title">MM status:</td>
          <td class="details-td-prop">{{ selectedRow.mmStatus }}</td>
        </tr>
        <tr>
          <td class="details-td-title">GDB id:</td>
          <td class="details-td-prop">{{ selectedRow.gdbId }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Id lokalid:</td>
          <td class="details-td-prop">{{ selectedRow.idLokalid }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Datacheck status:</td>
          <td class="details-td-prop">{{ selectedRow.dcStatus }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Objekt type:</td>
          <td class="details-td-prop">{{ selectedRow.objekttype }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Objekt tidsstempel:</td>
          <td class="details-td-prop">{{ selectedRow.objektTidsstempel }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Datacheck:</td>
          <td class="details-td-prop">{{ selectedRow.datacheck }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Datacheck bemærkning:</td>
          <td class="details-td-prop">{{ selectedRow.datacheckBem }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Datacheck kilde:</td>
          <td class="details-td-prop">{{ selectedRow.datacheckKilde }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Datacheck tidsstempel:</td>
          <td class="details-td-prop">{{ selectedRow.dcTidsstempel }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Korrektionsdato:</td>
          <td class="details-td-prop">{{ selectedRow.korrektionsdato }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Transaktionsid:</td>
          <td class="details-td-prop">{{ selectedRow.transaktionsid }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Ignoreret:</td>
          <td class="details-td-prop">{{ selectedRow.ignoreret }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Datainfo:</td>
          <td class="details-td-prop">{{ selectedRow.datainfo }}</td>
        </tr>
      </table>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.id)"> {{ row.id }}</td>
        </ng-container>

        <!-- Objekttype Column -->
        <ng-container matColumnDef="objekttype">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Objekttype</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.objekttype)"> {{ row.objekttype }}</td>
        </ng-container>

        <!-- MM Id Column -->
        <ng-container matColumnDef="mmId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MM id</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.mmId)"> {{ row.mmId }}</td>
        </ng-container>

        <!-- GDB Id Column -->
        <ng-container matColumnDef="gdbId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>GDB id</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.gdbId)"> {{ row.gdbId }}</td>
        </ng-container>

        <!-- Id Lokalid Column -->
        <ng-container matColumnDef="idLokalid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id lokalid</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.idLokalid)"> {{ row.idLokalid }}</td>
        </ng-container>

        <!-- Datacheck Status Column -->
        <ng-container matColumnDef="dcStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datacheck status</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.dcStatus)"> {{ row.dcStatus }}</td>
        </ng-container>

        <!-- Datacheck Kilde Column -->
        <ng-container matColumnDef="datacheckKilde">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datacheck kilde</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.datacheckKilde)"> {{ row.datacheckKilde }}</td>
        </ng-container>

        <!-- Datacheck Column -->
        <ng-container matColumnDef="datacheck">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datacheck</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.datacheck)"> {{ row.datacheck }}</td>
        </ng-container>

        <!-- Datacheck Bem Column -->
        <ng-container matColumnDef="datacheckBem">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datacheck bemærkning</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.datacheckBem)"> {{ row.datacheckBem }}</td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
            (click)="onRowClicked(row)"
            (mouseenter)="onRowHover(row)"
            (mouseleave)="onRowLeave()"
            [ngClass]="{
              'selected-row': selectedRow === row,
              'uneven-row': selectedRow !== row && !isEven(i),
              'even-row': selectedRow !== row && isEven(i)
            }">
        </tr>
      </table>
    </div>
  </div>
</div>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" [hasBackdrop]="false" (mouseleave)="closeContextMenu()">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="onFilter(filterInput, item)">Brug som filter</button>
  </ng-template>
</mat-menu>
