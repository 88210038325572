import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Observable, from } from 'rxjs';
import { HistoryView } from '../models/history-view.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CosmosClient } from '@azure/cosmos';
import {IHistoryViewService} from "./ihistory-view-service";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoryViewCosmosService extends BaseService implements IHistoryViewService {
  private client: CosmosClient;
  private databaseId = 'datacheck';
  private containerId = 'history_view';

  constructor(snackBar: MatSnackBar) {
    super(snackBar);
    this.client = new CosmosClient({ endpoint: environment.cosmosEndpoint, key: environment.cosmosKey });
  }

  getData(): Observable<HistoryView[]> {
    const container = this.client.database(this.databaseId).container(this.containerId);
    const querySpec = {
      query: `
        SELECT
        c.OBJEKTTYPE AS objekttype,
        c.DATACHECK_KILDE AS datacheckKilde,
        c.STATUS AS status,
        c.OBJEKT_AKTUALITET AS objektAktualitet,
        c.DATACHECK AS datacheck,
        c["Denne uge"] AS denneUge,
        c["1 uge siden"] AS _1UgeSiden,
        c["2 uger siden"] AS _2UgerSiden,
        c["3 uger siden"] AS _3UgerSiden,
        c["4 uger siden"] AS _4UgerSiden,
        c["5 uger siden"] AS _5UgerSiden,
        c["6 uger siden"] AS _6UgerSiden,
        c["7 uger siden"] AS _7UgerSiden,
        c["8 uger siden"] AS _8UgerSiden
      FROM c
      `
    };

    console.log('Executing query:', querySpec.query);

    return from(container.items.query<HistoryView>(querySpec).fetchAll().then(response => {
      console.log('Query response:', response.resources);
      return response.resources.sort((a, b) => {
        const objekttypeComparison = a.objekttype.localeCompare(b.objekttype);
        if (objekttypeComparison !== 0) {
          return objekttypeComparison;
        }
        const datacheckSourceComparison = a.datacheckKilde.localeCompare(b.datacheckKilde);
        if (datacheckSourceComparison !== 0) {
          return datacheckSourceComparison;
        }
        return a.datacheck.localeCompare(b.datacheck);
      })
    }).catch(error => {
      console.error('Error during query execution:', error);
      this.snackBar.open('An error occurred while fetching data. Please try again later.', 'Close', {
        duration: 5000,
      });
      throw error;
    }));
  }
}
