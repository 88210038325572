import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ProcessedView} from '../models/processed-view.model';
import {catchError, map} from "rxjs/operators";
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ProcessedViewService extends BaseService {
  private apiUrl = this.baseApiUrl + 'ProcessedView';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getData(): Observable<ProcessedView[]> {
    return this.http.get<ProcessedView[]>(this.apiUrl)
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .pipe(
        map(results => this.modifyField(results))
    );
  }

  // Method to modify a field in ProcessedView
  private modifyField(results: ProcessedView[]): ProcessedView[] {
    return results.map(result => {
      // We change ',' to ' , ' in dataCheckBem in order to make the table column widths work properly
      if (result.datacheckBem) {
        result.datacheckBem = result.datacheckBem.split(',').join(' , ');
      }
      return result;
    });
  }
}
