import { Routes } from '@angular/router';
import { ResultViewComponent } from './components/result-view/result-view.component';
import { HistoryViewComponent } from './components/history-view/history-view.component';
import { ProcessedViewComponent } from './components/processed-view/processed-view.component';
import { AllResultsComponent } from './components/all-results/all-results.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { StoredProceduresComponent } from './components/stored-procedures/stored-procedures.component';
import {LogsComponent} from "./components/logs/logs.component";
import {SettingsComponent} from "./components/settings/settings.component";

export const appRoutes: Routes = [
  { path: 'result-view', component: ResultViewComponent, data: { title: 'Oversigt over resultater' } },
  { path: 'history-view', component: HistoryViewComponent , data: { title: 'Oversigt over historik' }},
  { path: 'processed-view', component: ProcessedViewComponent, data: { title: 'Oversigt over behandlede' } },
  { path: 'all-results', component: AllResultsComponent, data: { title: 'Alle resultater' } },
  { path: 'jobs', component: JobsComponent, data: { title: 'Jobs' } },
  { path: 'stored-procedures', component: StoredProceduresComponent, data: { title: 'Procedurer' } },
  { path: 'logs', component: LogsComponent, data: { title: 'Logs' } },
  { path: 'settings', component: SettingsComponent, data: { title: 'Indstillinger' } },
  { path: '', redirectTo: 'result-view', pathMatch: 'full' }, // Default route
  { path: '**', redirectTo: 'result-view' } // Wildcard route for a 404 page
];
