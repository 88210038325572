import { Component } from '@angular/core';
import {MatDialogModule, MatDialogRef, MatDialogState} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-password-dialog',
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule
  ],
  template: `
    <h1 mat-dialog-title>Indtast adgangskode</h1>
    <div mat-dialog-content>
      <mat-form-field>
        <mat-label>Adgangskode</mat-label>
        <input matInput [(ngModel)]="password" type="password">
      </mat-form-field>
    </div>
    <div mat-dialog-actions style="justify-content: flex-end;">
      <button mat-raised-button color="primary" (click)="onCancel()">Annuller</button>
      <button mat-raised-button color="primary" (click)="onConfirm()" type="submit">Ok</button>
    </div>
  `,
  standalone: true
})
export class PasswordDialogComponent {
  password: string = '';

  constructor(public dialogRef: MatDialogRef<PasswordDialogComponent>) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    if (this.dialogRef.getState() === MatDialogState.OPEN) {
      this.dialogRef.close(this.password);
    }
  }
}
