<div class="vertical-container">
  <div class="filter-container">
    <input #filterInput matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
    <button mat-icon-button (click)="clearFilter(filterInput)">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-paginator [pageSizeOptions]="[50, 100, 200, 500]" showFirstLastButtons></mat-paginator>
  <div class="container">
    <div class="details-container" *ngIf="selectedRow">
      <h3 class="details-header">Detaljer for logbesked</h3>
      <table class="details-table">
        <tr>
          <td class="details-td-title">Besked type:</td>
          <td class="details-td-prop">{{ selectedRow.beskedtype }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Id:</td>
          <td class="details-td-prop">{{ selectedRow.id }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Tidsstempel:</td>
          <td class="details-td-prop">{{ selectedRow.timestamp }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Objekttype:</td>
          <td class="details-td-prop">{{ selectedRow.objekttype }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Datacheck kilde:</td>
          <td class="details-td-prop">{{ selectedRow.datacheckKilde }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Job log id:</td>
          <td class="details-td-prop">{{ selectedRow.jobLogId }}</td>
        </tr>
        <tr>
          <td class="details-td-title">Logbesked:</td>
          <td class="details-td-prop">{{ selectedRow.msg }}</td>
        </tr>
      </table>
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="desc" class="mat-elevation-z8">
        <!-- Beskedtype Column -->
        <ng-container matColumnDef="beskedtype">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            <img *ngIf="row.beskedtype === 1" src="assets/information-svgrepo-com.svg" alt="Information Icon" class="small-icon">
            <img *ngIf="row.beskedtype === 2" src="assets/alarm-bell-alert-svgrepo-com.svg" alt="Alarm Icon" class="small-icon">
          </td>
        </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.id }}</td>
        </ng-container>

        <!-- Timestamp Column -->
        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tidsstempel</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.objekttype)">
            {{ row.timestamp | date: 'dd-MM-yyyy HH:mm:ss':'Europe/Copenhagen' }}
          </td>
        </ng-container>

        <!-- Objekttype Column -->
        <ng-container matColumnDef="objekttype">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Objekttype</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.objekttype)"> {{ row.objekttype }}</td>
        </ng-container>

        <!-- Datacheck Kilde Column -->
        <ng-container matColumnDef="datacheckKilde">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datacheck kilde</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.objekttype)"> {{ row.datacheckKilde }}</td>
        </ng-container>

        <!-- Logbesked Column -->
        <ng-container matColumnDef="msg">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Logbesked</th>
          <td mat-cell *matCellDef="let row" class="table-cell" (contextmenu)="openContextMenu($event, row, row.objekttype)"> {{ row.msg }}</td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
            (click)="onRowClicked(row)"
            (mouseenter)="onRowHover(row)"
            (mouseleave)="onRowLeave()"
            [ngClass]="{
            'selected-row': selectedRow === row,
            'uneven-row': selectedRow !== row && !isEven(i),
            'even-row': selectedRow !== row && isEven(i)
          }">
        </tr>
      </table>
    </div>
  </div>
</div>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" [hasBackdrop]="false" (mouseleave)="closeContextMenu()">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="onFilter(filterInput, item)">Brug som filter</button>
  </ng-template>
</mat-menu>
