import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ResultUniqueDataCheckRemarkService extends BaseService {
  private apiUrl = this.baseApiUrl + 'ResultsDataCheckRemark';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getData(): Observable<string[]> {
    return this.http.get<string[]>(this.apiUrl)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

}
