import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UpdateStoredProcedureService extends BaseService {
  private apiUrl = this.baseApiUrl + 'UpdateStoredProcedure';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  updateStoredProcedures(storedProcedureIds: string[], status: number): Observable<any> {
    console.log('Updating stored procedures:', storedProcedureIds);

    const params = new HttpParams()
      .set('ids', storedProcedureIds.join(','))
      .set('status', status.toString());

    return this.http.put(`${this.apiUrl}`, null, { params })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .pipe(
        map(() => true)
      );
  }
}
