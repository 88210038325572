/** @hidden */
export class OrderByEndpointComponent {
    /**
     * Represents an endpoint in handling an order by query. For each processed orderby
     * result it returns 'payload' item of the result
     *
     * @param executionContext - Underlying Execution Context
     * @hidden
     */
    constructor(executionContext, emitRawOrderByPayload = false) {
        this.executionContext = executionContext;
        this.emitRawOrderByPayload = emitRawOrderByPayload;
    }
    /**
     * Execute a provided function on the next element in the OrderByEndpointComponent.
     */
    async nextItem(diagnosticNode) {
        const { result: item, headers } = await this.executionContext.nextItem(diagnosticNode);
        if (this.emitRawOrderByPayload) {
            return {
                result: item !== undefined ? item : undefined,
                headers,
            };
        }
        else {
            return {
                result: item !== undefined ? item.payload : undefined,
                headers,
            };
        }
    }
    /**
     * Determine if there are still remaining resources to processs.
     * @returns true if there is other elements to process in the OrderByEndpointComponent.
     */
    hasMoreResults() {
        return this.executionContext.hasMoreResults();
    }
}
