import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HistoryView} from '../models/history-view.model';
import {catchError, map} from "rxjs/operators";
import {MatSnackBar} from '@angular/material/snack-bar';
import {IHistoryViewService} from "./ihistory-view-service";

@Injectable({
  providedIn: 'root'
})
export class HistoryViewService extends BaseService implements IHistoryViewService {
  private apiUrl = this.baseApiUrl + 'HistoryView';

  constructor(private http: HttpClient, snackBar: MatSnackBar) {
    super(snackBar);
  }

  getData(): Observable<HistoryView[]> {
    return this.http.get<HistoryView[]>(this.apiUrl)
      .pipe(
        catchError(this.handleError.bind(this))
      )
  }
}
