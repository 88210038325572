import { Component } from '@angular/core';
import {BackEndVersion} from "../../models/backend-version.model";
import {VersionService} from "../../services/version.service";
import {MatFormField} from "@angular/material/form-field";
import {FormsModule} from "@angular/forms";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatInput, MatLabel} from "@angular/material/input";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef, MatRow, MatRowDef,
  MatTable, MatTableDataSource
} from "@angular/material/table";
import {NgClass, NgIf} from "@angular/common";
import {EmailNotification} from "../../models/email-notification.model";
import {EmailNotificationService} from "../../services/email-notification.service";
import {RecipientTypeEnum} from "../../models/recipient-type.enum";

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    MatFormField,
    FormsModule,
    MatCheckbox,
    MatInput,
    MatLabel,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatTable,
    NgIf,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatHeaderCellDef,
    NgClass
  ],
  providers: [
    VersionService,
    EmailNotificationService
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent {
  // Define your settings properties here
  recipientEmail: string = '';
  recipientName: string = '';
  notificationError: boolean = true;
  notificationNewDataErrors: boolean = true;
  notificationJobCompletion: boolean = true;
  includeLogJobCompletion: boolean = true;
  statusKode: number | undefined = undefined;

  frontEndVersion: string = '';
  backEndVersion: BackEndVersion = { backEndServiceVersion: '', databaseSchemaVersion: '', environment: '' };

  selectedRow: any;
  emailNotificationFormChanged: boolean = false;

  dataSource = new MatTableDataSource<EmailNotification>();

  displayedColumns: string[] = ['modtagertype', 'statusKode', 'modtagerMail', 'modtagerNavn', 'notifikationFejl', 'notifikationNyeDatafejl', 'notifikationJobAfslutning', 'inkluderLogJobAfslutning'];

  constructor(
    private versionService: VersionService,
    private emailNotificationService: EmailNotificationService
  ) {}

  ngOnInit(): void {
    this.frontEndVersion = this.versionService.getFrontEndVersion();
    this.versionService.getBackEndVersion().subscribe(version => {
      this.backEndVersion = version;
    });

    this.fetchData();
  }

  fetchData(): void {
    this.emailNotificationService.getData().subscribe(fetchedData => {
      this.dataSource.data = fetchedData;
      this.selectedRow = null;
      this.recipientEmail = '';
      this.recipientName = '';
      this.notificationError = true;
      this.notificationNewDataErrors = true;
      this.notificationJobCompletion = true;
      this.statusKode = undefined;
    });
  }

  onRowClicked(row: any): void {
    console.log('Row clicked: ', row);
    this.selectedRow = row;
    if (this.selectedRow) {
      this.recipientEmail = this.selectedRow.modtagerMail;
      this.recipientName = this.selectedRow.modtagerNavn;
      this.notificationError = this.selectedRow.notifikationFejl;
      this.notificationNewDataErrors = this.selectedRow.notifikationNyeDatafejl;
      this.notificationJobCompletion = this.selectedRow.notifikationJobAfslutning;
      this.includeLogJobCompletion = this.selectedRow.inkluderLogJobAfslutning
      this.statusKode = this.selectedRow.statusKode;
      this.emailNotificationFormChanged = false;
    }
  }

onAddEmailNotification(): void {
    const emailNotification: EmailNotification = {
      modtagerType: RecipientTypeEnum.User,
      modtagerMail: this.recipientEmail,
      modtagerNavn: this.recipientName,
      notifikationFejl: this.notificationError,
      notifikationNyeDatafejl: this.notificationNewDataErrors,
      notifikationJobAfslutning: this.notificationJobCompletion,
      inkluderLogJobAfslutning: this.includeLogJobCompletion
    };

    this.emailNotificationService.postData(emailNotification).subscribe(() => {
      this.fetchData();
      this.emailNotificationFormChanged = false;
    });
  }

  onUpdateEmailNotification(): void {
    if (this.selectedRow) {
      const emailNotification: EmailNotification = {
        id: this.selectedRow.id,
        modtagerType: RecipientTypeEnum.User,
        modtagerMail: this.recipientEmail,
        modtagerNavn: this.recipientName,
        notifikationFejl: this.notificationError,
        notifikationNyeDatafejl: this.notificationNewDataErrors,
        notifikationJobAfslutning: this.notificationJobCompletion,
        inkluderLogJobAfslutning: this.includeLogJobCompletion,
        statusKode: this.statusKode
      };

      this.emailNotificationService.putData(emailNotification).subscribe(() => {
        this.fetchData();
        this.emailNotificationFormChanged = false;
      });
    }
  }

  onDeleteEmailNotification(): void {
    if (this.selectedRow) {
      this.emailNotificationService.deleteData(this.selectedRow).subscribe(() => {
        this.fetchData();
        this.emailNotificationFormChanged = false;
      });
    }
  }

  onEmailNotificationFormChange(): void {
    if (this.selectedRow) {
      this.emailNotificationFormChanged = this.recipientEmail !== this.selectedRow.modtagerMail ||
        this.recipientName !== this.selectedRow.modtagerNavn ||
        this.notificationError !== this.selectedRow.notifikationFejl ||
        this.notificationNewDataErrors !== this.selectedRow.notifikationNyeDatafejl ||
        this.notificationJobCompletion !== this.selectedRow.notifikationJobAfslutning;
    }
    else {
      this.emailNotificationFormChanged = true;
    }
  }
}
