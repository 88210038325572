<div class="settings-container">
  <h2>Indstillinger</h2>
  <h3>Versionsinfo</h3>
  <div class="versionsinfo-container">
    <mat-form-field>
      <mat-label>Front-End Version</mat-label>
      <input matInput [value]="frontEndVersion" readonly>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Back-End Service Version</mat-label>
      <input matInput [value]="backEndVersion.backEndServiceVersion" readonly>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Database Skema Version</mat-label>
      <input matInput [value]="backEndVersion.databaseSchemaVersion" readonly>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Produktionsmiljø</mat-label>
      <input matInput [value]="backEndVersion.environment" readonly>
    </mat-form-field>
  </div>

  <h3>E-mail notifikation</h3>
  <form  #emailNotificationForm="ngForm">
    <div class="email-info-container">
      <mat-form-field style="width: 500px;">
        <mat-label>Modtager email</mat-label>
        <input matInput [(ngModel)]="recipientEmail" name="recipientEmail" (ngModelChange)="onEmailNotificationFormChange()" required>
      </mat-form-field>
    </div>
      <div class="email-info-container">
      <mat-form-field style="width: 500px;">
        <mat-label>Modtager navn</mat-label>
        <input matInput [(ngModel)]="recipientName" name="recipientName" (ngModelChange)="onEmailNotificationFormChange()" required>
      </mat-form-field>
    </div>
    <div class="email-info-container">
      <mat-checkbox [(ngModel)]="notificationError" name="notificationError" (click)="onEmailNotificationFormChange()">Notifikation ved fejl</mat-checkbox>
      <mat-checkbox [(ngModel)]="notificationNewDataErrors" name="notificationNewDataErrors" (click)="onEmailNotificationFormChange()">Notifikation ved nye datafejl</mat-checkbox>
      <mat-checkbox [(ngModel)]="notificationJobCompletion" name="notificationJobCompletion" (click)="onEmailNotificationFormChange()">Notifikation ved jobafslutning</mat-checkbox>
      <mat-checkbox [(ngModel)]="includeLogJobCompletion" name="includeLogJobCompletion" (click)="onEmailNotificationFormChange()">Inkluder log ved jobafslutning</mat-checkbox>
    </div>
    <div class="email-info-container">
      <button [disabled]="!emailNotificationForm.form.valid || !emailNotificationFormChanged" (click)="onAddEmailNotification()">Tilføj</button>
      <button [disabled]="!emailNotificationForm.form.valid || !emailNotificationFormChanged" (click)="onUpdateEmailNotification()">Opdater</button>
      <button [disabled]="!selectedRow" (click)="onDeleteEmailNotification()">Slet</button>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Recipient Type Column -->
      <ng-container matColumnDef="modtagertype">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let row" class="table-cell">
          <img *ngIf="row.modtagertype === 1" src="assets/laptop-mail-svgrepo-com.svg" alt="User Icon" class="small-icon">
        </td>
      </ng-container>

      <!-- Status Code Column -->
      <ng-container matColumnDef="statusKode">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.statusKode }}</td>
      </ng-container>

      <!-- Recipient E-mail Column -->
      <ng-container matColumnDef="modtagerMail">
        <th mat-header-cell *matHeaderCellDef>Modtager mail adresse</th>
        <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.modtagerMail }}</td>
      </ng-container>

      <!-- Recipient Column -->
      <ng-container matColumnDef="modtagerNavn">
        <th mat-header-cell *matHeaderCellDef>Modtager navn</th>
        <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.modtagerNavn }}</td>
      </ng-container>

      <!-- Notify On Error -->
      <ng-container matColumnDef="notifikationFejl">
        <th mat-header-cell *matHeaderCellDef>Notifikation ved fejl</th>
        <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.notifikationFejl ? 'Ja' : 'Nej' }}</td>
      </ng-container>

      <!-- Notify On New Data Errors -->
      <ng-container matColumnDef="notifikationNyeDatafejl">
        <th mat-header-cell *matHeaderCellDef>Notifikation ved nye datafejl</th>
        <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.notifikationNyeDatafejl ? 'Ja' : 'Nej' }}</td>
      </ng-container>

      <!-- Notify On Job Completion -->
      <ng-container matColumnDef="notifikationJobAfslutning">
        <th mat-header-cell *matHeaderCellDef>Notifikation ved jobafslutning</th>
        <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.notifikationJobAfslutning ? 'Ja' : 'Nej' }}</td>
      </ng-container>

      <!-- Include Log On Job Completion -->
      <ng-container matColumnDef="inkluderLogJobAfslutning">
        <th mat-header-cell *matHeaderCellDef>Inkluder log ved jobafslutning</th>
        <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.inkluderLogJobAfslutning ? 'Ja' : 'Nej' }}</td>
      </ng-container>

      <!-- Header and Row Declarations -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
          (click)="onRowClicked(row)"
          [ngClass]="{
              'selected-row': selectedRow === row
            }">
      </tr>
    </table>

  </form>
</div>
