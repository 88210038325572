import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, ActivatedRoute, NavigationEnd, RouterOutlet, RouterModule } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { VersionService } from './services/version.service';
import { InjectionToken } from '@angular/core';
import {IResultViewService} from "./services/iresult-view-service";
import {environment} from "../environments/environment";
import {ResultViewCosmosService} from "./services/result-view-cosmos.service";
import {ResultViewService} from "./services/result-view.service";
import {IHistoryViewService} from "./services/ihistory-view-service";
import {HistoryViewCosmosService} from "./services/history-view-cosmos.service";
import {HistoryViewService} from "./services/history-view.service";

export const HISTORY_VIEW_SERVICE = new InjectionToken<IHistoryViewService>('HistoryViewService');
export const RESULT_VIEW_SERVICE = new InjectionToken<IResultViewService>('ResultViewService');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    RouterOutlet,
    RouterModule
],
  providers: [
    VersionService,
    { provide: RESULT_VIEW_SERVICE, useClass: environment.useCosmos ? ResultViewCosmosService : ResultViewService },
    { provide: HISTORY_VIEW_SERVICE, useClass: environment.useCosmos ? HistoryViewCosmosService : HistoryViewService }
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'DataChecker';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private versionService: VersionService
  ) {}

  ngOnInit(): void {
    // Update front-end version to let the back-end know which version of the front-end is running
    const frontEndVersion = this.versionService.getFrontEndVersion();
    this.versionService.putFrontEndVersion(frontEndVersion).subscribe(() => {
      console.log('Front-end version updated successfully');
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      map(route => route.snapshot.data['title'])
    ).subscribe(title => {
      this.title = title;
    });
  }

  isRouteActive(route: string): boolean {
    return this.router.url === route;
  }
}
