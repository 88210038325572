<div class="container">
  <div class="buttons-container">
    <button mat-raised-button color="primary" (click)="startProcedures(useTimestamp)" [disabled]="selection.isEmpty()">Udfør valgte procedurer</button>
    <button mat-raised-button color="primary" (click)="resetProcedures()" [disabled]="selection.isEmpty()">Nulstil valgte procedurer</button>
    <mat-checkbox #useTimestamp color="primary" [(ngModel)]="useTimestamp">Kør med tidsstempel</mat-checkbox>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let row" class="table-cell">
          <img *ngIf="row.status === StatusIcons.Neutral" src="assets/night-moon-svgrepo-com.svg" alt="Neutral Icon" class="small-icon">
          <img *ngIf="row.status === StatusIcons.Running" src="assets/running-svgrepo-com.svg" alt="Neutral Icon" class="small-icon">
          <img *ngIf="row.status === StatusIcons.Error" src="assets/alarm-bell-alert-svgrepo-com.svg" alt="Neutral Icon" class="small-icon">
          <img *ngIf="row.status === StatusIcons.Completed" src="assets/sun-with-face-svgrepo-com.svg" alt="Neutral Icon" class="small-icon">
        </td>
      </ng-container>

      <!-- Navn Column -->
      <ng-container matColumnDef="navn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Procedurenavn</th>
        <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.navn }}</td>
      </ng-container>

      <!-- Header and Row Declarations -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
          (click)="onRowClicked(row)"
          (mouseenter)="onRowHover(row)"
          (mouseleave)="onRowLeave()"
          [ngClass]="{
              'selected-row': selectedRow === row,
              'uneven-row': selectedRow !== row && !isEven(i),
              'even-row': selectedRow !== row && isEven(i)
            }">
      </tr>
    </table>
  </div>

  <div class="table-container" *ngIf="selectedRow">
    <mat-paginator [pageSizeOptions]="[50, 100, 200, 500]" showFirstLastButtons></mat-paginator>
    <div class="log-container">
      <table mat-table [dataSource]="dataSourceLog" #sortLogMessages="matSort" matSort matSortActive="id" matSortDirection="desc" class="mat-elevation-z8">
        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.id }}</td>
        </ng-container>

        <!-- Msg Column -->
        <ng-container matColumnDef="msg">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Logbesked</th>
          <td mat-cell *matCellDef="let row" class="table-cell"> {{ row.msg }}</td>
        </ng-container>

        <!-- Tidsstempel Column -->
        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tidsstempel</th>
          <td mat-cell *matCellDef="let row" class="table-cell">
            {{ row.timestamp | date: 'dd-MM-yyyy HH:mm:ss':'Europe/Copenhagen' }}
          </td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr mat-header-row *matHeaderRowDef="displayedColumnsLog"></tr>
        <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumnsLog;"
            (click)="onRowClickedLog(row)"
            (mouseenter)="onRowHoverLog(row)"
            (mouseleave)="onRowLeaveLog()"
            [ngClass]="{
              'selected-row': selectedRowLog === row,
              'uneven-row': selectedRowLog !== row && !isEvenLog(i),
              'even-row': selectedRowLog !== row && isEvenLog(i)
            }">
        </tr>
      </table>
    </div>
  </div>
</div>
